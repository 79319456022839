<template>
  <v-overlay>
    <VProgressCircular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>
import { getFragment } from '@/application/util/url.js';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'AuthorisationCallback',
  methods: {
    ...mapMutations({
      setAuthorisationToken: 'authorisation/setAuthorisationToken',
    }),
    ...mapActions({
      fetchProfile: 'authorisation/fetchProfile',
    }),
  },
  async created() {
    const redirectUri = this.$route.query.redirect_uri;

    const token = getFragment('token');

    if (!token) throw new Error('AuthorisationCallback::created token hash not found');

    this.setAuthorisationToken(token);
    await this.fetchProfile();
    this.$router.replace({ name: redirectUri });
  },
};
</script>
